import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import PaymentsAPIServiceV2, {
    AvailableShifts,
    CurrentPeriodShifts,
    TransactionsResponse
} from '../../services/PaymentsServiceV2';
import { BaseAPIState, FETCH_STATE } from './types';
import {
    CompanyPaymentDetailsResponse,
    DepartmentsResponse,
    GetAiiaRetryAuthorizeUrlRequest,
    GetIntectUsersDetailedReqest,
    GetZenegyUsersDetailedReqest,
    IntectDepartmentsResponse,
    IntectEmploymentTemplatesResponse,
    IntectUserDetailed,
    PlanDayEmployeeTypeResponse,
    UsersDetailedResponse,
    ZenegyUserDetailed,
    CompanyBalanceResponse,
    TimezonesResponse,
    CompanyDetailsResponse,
    CompanyDanskeBankSettings,
    UpdateCompanyDanskeBankSettingsRequest,
    IntegrationProvider,
    IntegrationStatusResponse,
    UpdateTimeRegistrationProviderRequest,
    PlandaySetupRequest,
    IntectAuthorizeRequest,
    UpdateSalaryProviderRequest,
    UpdateSalaryModesRequest,
    UpdateSalaryTypesRequest,
    UpdateEmployeeTypesRequest,
    UpdateDepartmentsRequest,
    ZenegySalaryTypeEnum,
    ZenegyEmployeeTypeEnum,
    ZenegySalaryModeEnum,
    Department,
    ImportUser,
    EmployeeTypesResponse,
    SalaryTypeResponse,
    UpdateIntectDepartmentsRequest,
    UpdateIntectEmploymentTemplateRequest,
    FailTransactionsRequest,
    SucceedTransactionsRequest,
    TransactionsRequestResponse,
    RejectTransactionsRequest,
    ApproveTransactionsRequest,
    CreateCompanyAccountTransactionRequest
} from '../../models/payment';

export interface UpcomingShiftsState {
    currentPeriodShifts?: CurrentPeriodShifts;
    isLoading: boolean;
    error: any;
}

export interface AvailableShiftsState {
    availableShifts?: AvailableShifts;
    isLoading: boolean;
    error: any;
}

export interface TransactionsHistoryState extends BaseAPIState {
    transactions?: TransactionsResponse;
    isLoading: boolean;
    error: any;
}

export interface UserTransactionHistoryState extends BaseAPIState {
    transactions?: TransactionsResponse;
    isLoading: boolean;
    error: any;
}

export interface AiiaRefreshPaymentState extends BaseAPIState {}

export interface AiiaRetryAuthorizeState extends BaseAPIState {}

export interface AiiaRegenerateAuthorizeUrlState extends BaseAPIState {}

export interface ZenergyUsersDetailedState extends BaseAPIState {
    usersResponse?: UsersDetailedResponse<ZenegyUserDetailed>;
}

export interface IntectUsersDetailedState extends BaseAPIState {
    usersResponse?: UsersDetailedResponse<IntectUserDetailed>;
}

export interface currentCompaniesState {
    company?: CompanyPaymentDetailsResponse;
    isLoading: boolean;
    error: any;
}

interface DownloadParams {
    startDate: any;
    endDate: any;
}

export interface TransactionsDownloadState extends BaseAPIState {
    transactions?: TransactionsResponse;
}
export interface PaymentCompanyState extends BaseAPIState {
    company?: CompanyPaymentDetailsResponse;
}

export interface ZenergyDepartments extends BaseAPIState {
    zenegyDepartments?: DepartmentsResponse;
}

export interface PlandayEmployeeTypesState extends BaseAPIState {
    planDayEmployeeTypeResponse?: PlanDayEmployeeTypeResponse;
}

export interface IntectDepartmentState extends BaseAPIState {
    intectDepartments?: IntectDepartmentsResponse;
}

export interface IntectEmployeeTemplates extends BaseAPIState {
    intectEmployeeTemplates?: IntectEmploymentTemplatesResponse;
}

export interface currentCompaniesBalanceState extends BaseAPIState {
    list?: CompanyBalanceResponse;
}

export interface CurrentCompaniesState {
    list: CompanyPaymentDetailsResponse[];
    isLoading: boolean;
    error: any;
}

export interface TimeZoneState extends BaseAPIState {
    list?: TimezonesResponse;
}

export interface CompanyDetailsState extends BaseAPIState {
    company?: CompanyDetailsResponse;
}

export interface UpdateCompanyDetailsState extends BaseAPIState {}

export interface CompanyDanskeBankSettingsState extends BaseAPIState {
    list?: CompanyDanskeBankSettings;
}

export interface CompanyDanskeBankSettingsState extends BaseAPIState {
    list?: CompanyDanskeBankSettings;
}

export interface UpdateCompanyDanskeBankSettingsState extends BaseAPIState {}

export interface IntegrationStatusState extends BaseAPIState {
    list?: IntegrationStatusResponse;
}

export interface ZenegyConnectStates extends BaseAPIState {}

export interface updateTimeRegistrationProviderState extends BaseAPIState {
    list?: UpdateTimeRegistrationProviderRequest;
}

export interface PlandaySetupState extends BaseAPIState {
    list?: PlandaySetupRequest;
}

export interface IntectAuthorizeState extends BaseAPIState {
    list?: IntectAuthorizeRequest;
}

export interface UpdateSalaryProviderState extends BaseAPIState {
    salaryProvider?: UpdateSalaryProviderRequest;
}

export interface ZenegySalaryTypesState extends BaseAPIState {
    salaryType?: SalaryTypeResponse;
}

export interface ZenegySalaryModesState extends BaseAPIState {
    salaryMode?: ZenegySalaryModeEnum;
}

export interface ZenegyEmployeesState extends BaseAPIState {
    list?: EmployeeTypesResponse;
}

export interface UpdateZenegySalaryModesState extends BaseAPIState {
    data?: UpdateSalaryModesRequest;
}

export interface UpdateZenegySalaryTypesState extends BaseAPIState {
    data?: UpdateSalaryTypesRequest;
}

export interface UpdateZenegyEmployeeTypesState extends BaseAPIState {
    employeeType?: UpdateEmployeeTypesRequest;
}

export interface UpdateZenegyEmployeeTypesState extends BaseAPIState {
    employeeType?: UpdateEmployeeTypesRequest;
}

export interface UpdateZenegyDepartmentsState extends BaseAPIState {
    departments?: Department;
}

export interface UpdateIntectDepartmentsState extends BaseAPIState {
    departments?: Department;
}

export interface UpdateIntectEmploymentTemplates extends BaseAPIState {
    employmentTemplates?: UpdateIntectEmploymentTemplateRequest;
}

export interface ImportJobsState extends BaseAPIState {}

export interface CancelJobsState extends BaseAPIState {}

export interface CurrentJobStatusState extends BaseAPIState {
    jobId?: any;
}

export interface ImportUserState extends BaseAPIState {
    users?: ImportUser;
}

export interface RefreshImportParametersState extends BaseAPIState {}

export interface AiiaConnectUrlsState extends BaseAPIState {
    connectUrl?: string;
}
export interface ApproveTransactionInSalaryState extends BaseAPIState {
    transactions?: TransactionsRequestResponse;
}

export interface RejectTransactionInSalaryState extends BaseAPIState {
    transactions?: TransactionsRequestResponse;
}

export interface FailTransactionInSalaryState extends BaseAPIState {
    transactions?: TransactionsRequestResponse;
}

export interface SucceedTransactionInSalaryState extends BaseAPIState {
    transactions?: TransactionsRequestResponse;
}

export interface CreateCompanyAccountTransactionState extends BaseAPIState {
    data?: CreateCompanyAccountTransactionRequest;
}

export interface TransactionsPdfExportState extends BaseAPIState {
    data?: {
        fileUrl: string;
    };
}

export interface ReconciliationPdfExportState extends BaseAPIState {
    data?: {
        fileUrl: string;
    };
}

export interface PaymentsState {
    upcomingShifts: UpcomingShiftsState;
    availableShifts: AvailableShiftsState;
    transactionHistory: TransactionsHistoryState;
    userTransactionHistory: UserTransactionHistoryState;
    aiiaRefreshPayment: AiiaRefreshPaymentState;
    aiiaRetryAuthorize: AiiaRetryAuthorizeState;
    zenergyUsersDetailed: ZenergyUsersDetailedState;
    intectUsersDetailed: IntectUsersDetailedState;
    currentCompany: currentCompaniesState;
    downloadTransactions: TransactionsDownloadState;
    aiiaRegenerateAuthorizeUrl: AiiaRegenerateAuthorizeUrlState;
    paymentCompany: PaymentCompanyState;
    zenegyDepartments: ZenergyDepartments;
    planDayEmployeeType: PlandayEmployeeTypesState;
    intectDepartments: IntectDepartmentState;
    intectEmployeeTemplates: IntectEmployeeTemplates;
    reconciliationReportState: ReconciliationReportState;
    currentCompanyBalance: currentCompaniesBalanceState;
    timeZoneState: TimeZoneState;
    companyDetails: CompanyDetailsState;
    updateCompanyDetails: UpdateCompanyDetailsState;
    companyDanskeBankSettings: CompanyDanskeBankSettingsState;
    updateCompanyDanskeBank: UpdateCompanyDanskeBankSettingsState;
    integrationstatus: IntegrationStatusState;
    zenegyConnectUrl: ZenegyConnectStates;
    updateTimeRegistrationProviders: updateTimeRegistrationProviderState;
    plandaySetup: PlandaySetupState;
    intectAuthorizeState: IntectAuthorizeState;
    salaryProviderState: UpdateSalaryProviderState;
    salaryTypeState: ZenegySalaryTypesState;
    salaryModeState: ZenegySalaryModesState;
    updateSalaryMode: UpdateZenegySalaryModesState;
    updateSalaryType: UpdateZenegySalaryTypesState;
    updateEmployeeType: UpdateZenegyEmployeeTypesState;
    employeesType: ZenegyEmployeesState;
    departmentState: UpdateZenegyDepartmentsState;
    importJobState: ImportJobsState;
    cancelJobState: CancelJobsState;
    currentJobStatusState: CurrentJobStatusState;
    importUserState: ImportUserState;
    refreshParamState: RefreshImportParametersState;
    adminCompanies: AdminCompaniesState;
    aiiaConnect: AiiaConnectUrlsState;
    intectDepartmentState: UpdateIntectDepartmentsState;
    intectEmploymentTemplates: UpdateIntectEmploymentTemplates;
    failTransactions: FailTransactionInSalaryState;
    succeedTransactions: SucceedTransactionInSalaryState;
    approveTransactions: ApproveTransactionInSalaryState;
    rejectTransactions: RejectTransactionInSalaryState;
    companyAccountTransaction: CreateCompanyAccountTransactionState;
    exportTransactionsPdf: TransactionsPdfExportState;
    exportReconciliationPdf: ReconciliationPdfExportState;
}
export interface AdminCompaniesState extends BaseAPIState {
    companies: AdminCompany[];
}

export interface AdminCompany {
    companyId: string;
    name: string;
    vat: any;
    usersCount: number;
    pendingTransactionsCount: number;
    finalizedTransactionsCount: number;
    balance: number;
    withdrawed: number;
    creditAmount?: number;
    currency: string;
}

export interface ReconciliationReportResponse {
    reconciliationSummary: ReconciliationSummary;
    hourlyEmployeesReconciliation: HourlyEmployeesReconciliation;
    fullTimeEmployeesReconciliation: FullTimeEmployeesReconciliation;
}

export interface ReconciliationSummary {
    monthTotal: number;
    hourlyEmployeesSummary: HourlyEmployeesSummary;
    fullTimeEmployeesSummary: FullTimeEmployeesSummary;
}

export interface HourlyEmployeesSummary {
    payrollPeriodStart: string;
    payrollPeriodEnd: string;
    total: number;
}

export interface FullTimeEmployeesSummary {
    payrollPeriodStart: string;
    payrollPeriodEnd: string;
    total: number;
}

export interface HourlyEmployeesReconciliation {
    payrollPeriodStart: string;
    payrollPeriodEnd: string;
    total: number;
    reconciliationTransactions: ReconciliationTransaction[];
}

export interface ReconciliationTransaction {
    date: string;
    description: string;
    status: string;
    approvedBy: string;
    amount: number;
    sum: number;
}

export interface FullTimeEmployeesReconciliation {
    payrollPeriodStart: string;
    payrollPeriodEnd: string;
    total: number;
    reconciliationTransactions: ReconciliationTransaction[];
}

export interface ReconciliationReportState extends BaseAPIState {
    reconciliationReport?: ReconciliationReportResponse;
}

const initialState: PaymentsState = {
    upcomingShifts: {
        currentPeriodShifts: undefined,
        isLoading: false,
        error: null
    },
    availableShifts: {
        availableShifts: undefined,
        isLoading: false,
        error: null
    },
    transactionHistory: {
        transactions: undefined,
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE
    },
    aiiaRefreshPayment: {
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE
    },
    aiiaRetryAuthorize: {
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE
    },
    aiiaRegenerateAuthorizeUrl: {
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE
    },
    zenergyUsersDetailed: {
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE,
        usersResponse: undefined
    },
    intectUsersDetailed: {
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE,
        usersResponse: undefined
    },
    currentCompany: {
        isLoading: false,
        error: null
    },
    downloadTransactions: {
        transactions: undefined,
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE
    },
    paymentCompany: {
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE,
        company: undefined
    },
    zenegyDepartments: {
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE,
        zenegyDepartments: undefined
    },
    planDayEmployeeType: {
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE,
        planDayEmployeeTypeResponse: undefined
    },
    intectDepartments: {
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE,
        intectDepartments: undefined
    },
    intectEmployeeTemplates: {
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE,
        intectEmployeeTemplates: undefined
    },
    reconciliationReportState: {
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE,
        reconciliationReport: undefined
    },
    currentCompanyBalance: {
        list: undefined,
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE
    },
    userTransactionHistory: {
        transactions: undefined,
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE
    },
    timeZoneState: {
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE,
        list: undefined
    },
    companyDetails: {
        isLoading: false,
        status: FETCH_STATE.IDLE,
        error: null
    },
    updateCompanyDetails: {
        isLoading: false,
        status: FETCH_STATE.IDLE,
        error: null
    },
    companyDanskeBankSettings: {
        list: undefined,
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE
    },
    updateCompanyDanskeBank: {
        isLoading: false,
        status: FETCH_STATE.IDLE,
        error: null
    },
    integrationstatus: {
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE,
        list: undefined
    },
    zenegyConnectUrl: {
        isLoading: false,
        status: FETCH_STATE.IDLE,
        error: null
    },
    updateTimeRegistrationProviders: {
        isLoading: false,
        status: FETCH_STATE.IDLE,
        error: null,
        list: undefined
    },
    plandaySetup: {
        isLoading: false,
        status: FETCH_STATE.IDLE,
        error: null
    },
    intectAuthorizeState: {
        isLoading: false,
        status: FETCH_STATE.IDLE,
        error: null
    },
    salaryProviderState: {
        isLoading: false,
        status: FETCH_STATE.IDLE,
        error: null,
        salaryProvider: undefined
    },
    salaryTypeState: {
        isLoading: false,
        status: FETCH_STATE.IDLE,
        error: null,
        salaryType: undefined
    },
    salaryModeState: {
        isLoading: false,
        status: FETCH_STATE.IDLE,
        error: null,
        salaryMode: undefined
    },
    updateSalaryMode: {
        isLoading: false,
        status: FETCH_STATE.IDLE,
        error: null,
        data: undefined
    },
    employeesType: {
        isLoading: false,
        status: FETCH_STATE.IDLE,
        error: null,
        list: undefined
    },
    updateEmployeeType: {
        isLoading: false,
        status: FETCH_STATE.IDLE,
        error: null,
        employeeType: undefined
    },
    updateSalaryType: {
        isLoading: false,
        status: FETCH_STATE.IDLE,
        error: null,
        data: undefined
    },
    departmentState: {
        isLoading: false,
        status: FETCH_STATE.IDLE,
        error: null,
        departments: undefined
    },
    importJobState: {
        isLoading: false,
        status: FETCH_STATE.IDLE,
        error: null
    },
    cancelJobState: {
        isLoading: false,
        status: FETCH_STATE.IDLE,
        error: null
    },
    currentJobStatusState: {
        isLoading: false,
        status: FETCH_STATE.IDLE,
        error: null,
        jobId: undefined
    },
    importUserState: {
        isLoading: false,
        status: FETCH_STATE.IDLE,
        error: null,
        users: undefined
    },
    refreshParamState: {
        isLoading: false,
        status: FETCH_STATE.IDLE,
        error: null
    },
    adminCompanies: {
        companies: [],
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE
    },
    aiiaConnect: {
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE
    },
    failTransactions: {
        transactions: undefined,
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE
    },
    intectDepartmentState: {
        isLoading: false,
        status: FETCH_STATE.IDLE,
        error: null,
        departments: undefined
    },
    intectEmploymentTemplates: {
        isLoading: false,
        status: FETCH_STATE.IDLE,
        error: null,
        employmentTemplates: undefined
    },
    succeedTransactions: {
        transactions: undefined,
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE
    },
    approveTransactions: {
        transactions: undefined,
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE
    },
    rejectTransactions: {
        transactions: undefined,
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE
    },
    companyAccountTransaction: {
        data: undefined,
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE
    },
    exportTransactionsPdf: {
        data: undefined,
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE
    },
    exportReconciliationPdf: {
        data: undefined,
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE
    }
};

const PAYMENTS_SLICE_NAME = 'payments';

export const getUpcomingShifts = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getUpcomingShifts`,
    async (userId: string) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getUpcomingShifts(userId);
        return response.data.currentPeriodShifts;
    }
);

export const getAvailableShifts = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getAvailableShifts`,
    async (userId: string) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getAvailableShifts(userId);
        return response.data.availableShifts;
    }
);

export const postAiiaRefreshPayment = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/aiiaRefreshPayment`,
    async (transactionId: string) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().aiiaRefreshPayment(
                transactionId
            );
        return response.data;
    }
);

export const getAiiaRetryAuthorizeUrl = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getAiiaRetryAuthorizeUrl`,
    async (request: GetAiiaRetryAuthorizeUrlRequest) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getAiiaRetryAuthorizeUrl(
                request
            );
        window.location.href = response.data.authorizeUrl;
        return response.data;
    }
);

export const getAiiaRegenerateAuthorizeUrl = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/aiiaRegenerateAuthorizeUrl`,
    async (request: GetAiiaRetryAuthorizeUrlRequest) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getAiiaRegenerateAuthorizeUrl(
                request
            );
        window.location.href = response.data.authorizeUrl;
        return response.data;
    }
);

export const getZenergyUsersDetailed = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getZenergyUsersDetailed`,
    async (request: GetZenegyUsersDetailedReqest) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getUsersWithZenegyDetails(
                request
            );
        return response.data;
    }
);

export const getIntectUsersDetailed = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getIntectUsersDetailed`,
    async (request: GetIntectUsersDetailedReqest) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getUsersWithIntectDetails(
                request
            );
        return response.data;
    }
);

export const getCurrentPaymentCompany = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getCurrentPaymentCompany`,
    async () => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getCurrentCompany();
        return response.data;
    }
);

export interface TransactionsHistoryParams {
    userId: string;
    limit: number;
    offset: number;
    startDate?: any;
    endDate?: any;
}

export const getTransactionsHistory = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getTransactionsHistory`,
    async ({
        userId,
        limit = 7,
        offset = 0,
        startDate,
        endDate
    }: TransactionsHistoryParams) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getTransactionsHistory(
                limit,
                offset,
                startDate,
                endDate
            );
        return response.data;
    }
);

export const getUserTransactionsHistory = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getUserTransactionsHistory`,
    async ({ userId, limit = 7, offset = 0 }: TransactionsHistoryParams) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getUserTransactionHistory(
                userId,
                limit,
                offset
            );
        return response.data;
    }
);

export const getZenegyDepartments = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getZenergyDepartments`,
    async () => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getZenegyDepartments();
        return response.data;
    }
);

export const getPlanDayEmployeeTypes = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getPlanDayEmployeeTypes`,
    async () => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getPlandayEmployeeTypes();
        return response.data;
    }
);

export const getIntectDepartments = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getIntectDepartments`,
    async () => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getIntectDepartments();
        return response.data;
    }
);

export const getIntectEmploymentTemplates = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getIntectEmploymentTemplates`,
    async () => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getIntectEmploymentTemplates();
        return response.data;
    }
);

export const setAiiaRetryErrorHandled = createAction(
    `${PAYMENTS_SLICE_NAME}/setAiiaRetryErrorHandled`
);

export const getReconciliationReport = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getReconciliationReport`,
    async (date: { month: number; year: number }) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getReconciliation(
                date.month,
                date.year
            );
        return response.data;
    }
);

export const getDownloadTransactions = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getDownloadTransactions`,
    async ({ startDate, endDate }: DownloadParams) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getDownloadTransactions(
                startDate,
                endDate
            );
        return response.data;
    }
);

export const getReconciliationPdf = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getExportReconciliationPdf`,
    async (date: { month: number; year: number }) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getExportReconciliationPdf(
                date.month,
                date.year
            );
        return response.data;
    }
);

export const getTransactionsPdf = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getExportTransactionsPdf`,
    async ({ startDate, endDate }: DownloadParams) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getExportTransactionsPdf(
                startDate,
                endDate
            );
        return response.data;
    }
);

export const getCurrentCompany = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getCurrentCompany`,
    async () => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getCurrentCompany();
        return response.data;
    }
);

export const getCurrentCompanyBalanceData = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getCurrentCompanyBalance`,
    async () => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getCurrentCompanyBalance();
        return response.data;
    }
);

export const getTimezonesList = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getTimezones`,
    async () => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getTimezones();
        return response.data;
    }
);

export const getCompanyDetailsList = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getCompanyDetails`,
    async () => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getCompanyDetails();
        return response.data;
    }
);

export const updateCompanyInfo = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/updateCompanyDetails`,
    async (updateData: { request: CompanyDetailsResponse }, thunkAPI) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().updateCompanyDetails(
                updateData.request
            );
        thunkAPI.dispatch(getCompanyDetailsList());
        return response.data;
    }
);

export const getCompanyDanskeBankSettingsData = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getCompanyDanskeBankSettings`,
    async () => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getCompanyDanskeBankSettings();
        return response.data;
    }
);

export const updateCompanyDanskeBankSettingsInfo = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/updateCompanyDanskeBankSettings`,
    async (request: UpdateCompanyDanskeBankSettingsRequest) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().updateCompanyDanskeBankSettings(
                request
            );
        return response.data;
    }
);

export const getProviderIntegrationStatus = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getIntegrationStatus`,
    async (provider: IntegrationProvider) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getIntegrationStatus(
                provider
            );
        return response.data;
    }
);

export const getZenegyConnect = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getZenegyConnectUrl`,
    async () => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getZenegyConnectUrl();
        return response.data;
    }
);

export const updateTimeRegistrationProviders = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/updateTimeRegistrationProvider`,
    async (request: UpdateTimeRegistrationProviderRequest) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().updateTimeRegistrationProvider(
                request
            );
        return response.data;
    }
);

export const getPlandaySetup = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/plandaySetup`,
    async (request: PlandaySetupRequest) => {
        const response = await PaymentsAPIServiceV2.getInstance().plandaySetup(
            request
        );
        return response.data;
    }
);

export const intectAuthorizeSetup = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/intectAuthorize`,
    async (request: IntectAuthorizeRequest) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().intectAuthorize(request);
        return response.data;
    }
);

export const selectSalaryProvider = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/updateSalaryProvider`,
    async (request: UpdateSalaryProviderRequest) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().updateSalaryProvider(
                request
            );
        return response.data;
    }
);

export const getZenegySalaryTypesList = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getZenegySalaryTypes`,
    async () => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getZenegySalaryTypes();
        return response.data;
    }
);

export const getZenegySalaryModesList = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getZenegySalaryModes`,
    async () => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getZenegySalaryModes();
        return response.data;
    }
);

export const updateZenegySalaryMode = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/updateZenegySalaryModes`,
    async (request: UpdateSalaryModesRequest) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().updateZenegySalaryModes(
                request
            );
        return response.data;
    }
);

export const updateZenegySalaryType = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/updateZenegySalaryTypes`,
    async (request: UpdateSalaryTypesRequest) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().updateZenegySalaryTypes(
                request
            );
        return response.data;
    }
);

export const updateZenegyEmployeeType = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/updateZenegyEmployeeTypes`,
    async (request: UpdateEmployeeTypesRequest) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().updateZenegyEmployeeTypes(
                request
            );
        return response.data;
    }
);

export const getZenegyEmployeeTypesList = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getZenegyEmployeeTypes`,
    async () => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getZenegyEmployeeTypes();
        return response.data;
    }
);

export const updateZenegyDepartmentsList = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/updateZenegyDepartments`,
    async (request: UpdateDepartmentsRequest) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().updateZenegyDepartments(
                request
            );
        return response.data;
    }
);

export const updateIntectDepartmentsList = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/updateIntectDepartments`,
    async (request: UpdateIntectDepartmentsRequest) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().updateIntectDepartments(
                request
            );
        return response.data;
    }
);

export const updateIntectEmploymentTemplatesList = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/updateIntectEmploymentTemplates`,
    async (request: UpdateIntectEmploymentTemplateRequest) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().updateIntectEmploymentTemplates(
                request
            );
        return response.data;
    }
);

export const currentImportJob = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getCurrentImportJob`,
    async () => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getCurrentImportJob();
        return response.data;
    }
);

export const cancelCurrentJob = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/cancelJob`,
    async (jobId: string) => {
        const response = await PaymentsAPIServiceV2.getInstance().cancelJob(
            jobId
        );
        return response.data;
    }
);

export const getCurrentJobStatus = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getJobStatus`,
    async (jobId: string) => {
        const response = await PaymentsAPIServiceV2.getInstance().getJobStatus(
            jobId
        );
        return response.data;
    }
);

export const importCurrentUsers = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/importUsers`,
    async () => {
        const response = await PaymentsAPIServiceV2.getInstance().importUsers();
        return response.data;
    }
);

export const refreshCurrentImportParameters = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/refreshImportParameters`,
    async () => {
        const response =
            await PaymentsAPIServiceV2.getInstance().refreshImportParameters();
        return response.data;
    }
);

export const getAdminCompanies = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getAdminCompanies`,
    async () => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getAdminCompanies();
        return response.data.companies;
    }
);

export const aiiaConnectUrl = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/getAiiaConnectUrl`,
    async () => {
        const response =
            await PaymentsAPIServiceV2.getInstance().getAiiaConnectUrl();
        return response.data;
    }
);

export const approveTransactionInSalary = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/approveTransactions`,
    async (request: ApproveTransactionsRequest) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().approveTransactions(
                request
            );
        return response.data;
    }
);

export const rejectTransactionInSalary = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/rejectTransactions`,
    async (request: RejectTransactionsRequest) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().rejectTransactions(
                request
            );
        return response.data;
    }
);

export const failTransactionInSalary = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/failTransactionInSalarySystem`,
    async (request: FailTransactionsRequest) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().failTransactionInSalarySystem(
                request
            );
        return response.data;
    }
);

export const succeedTransactionInSalary = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/succeedTransactionInSalarySystem`,
    async (request: SucceedTransactionsRequest) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().succeedTransactionInSalarySystem(
                request
            );
        return response.data;
    }
);

export const createCompanyAccountsTransaction = createAsyncThunk(
    `${PAYMENTS_SLICE_NAME}/createCompanyAccountTransaction`,
    async (request: CreateCompanyAccountTransactionRequest) => {
        const response =
            await PaymentsAPIServiceV2.getInstance().createCompanyAccountTransaction(
                request
            );
        return response.data;
    }
);

const paymentsSlice = createSlice({
    name: PAYMENTS_SLICE_NAME,
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(setAiiaRetryErrorHandled, (state) => {
                state.aiiaRetryAuthorize.error = null;
                state.aiiaRetryAuthorize.status = FETCH_STATE.IDLE;
            })
            .addCase(getUpcomingShifts.pending, (state) => {
                state.upcomingShifts.isLoading = true;
            })
            .addCase(getUpcomingShifts.fulfilled, (state, action) => {
                state.upcomingShifts.currentPeriodShifts = action.payload;
                state.upcomingShifts.isLoading = false;
            })
            .addCase(getUpcomingShifts.rejected, (state, action) => {
                state.upcomingShifts.error = action.error;
                state.upcomingShifts.isLoading = false;
            })
            .addCase(getAvailableShifts.pending, (state) => {
                state.availableShifts.isLoading = true;
            })
            .addCase(getAvailableShifts.fulfilled, (state, action) => {
                state.availableShifts.availableShifts = action.payload;
                state.availableShifts.isLoading = false;
            })
            .addCase(getAvailableShifts.rejected, (state, action) => {
                state.availableShifts.error = action.error;
                state.availableShifts.isLoading = false;
            })
            .addCase(getTransactionsHistory.pending, (state) => {
                state.transactionHistory.status = FETCH_STATE.LOADING;
                state.transactionHistory.error = null;
            })
            .addCase(getTransactionsHistory.fulfilled, (state, action) => {
                state.transactionHistory.status = FETCH_STATE.SUCCEEDED;
                state.transactionHistory.transactions = action.payload;
                state.transactionHistory.error = null;
            })
            .addCase(getTransactionsHistory.rejected, (state, action) => {
                state.transactionHistory.status = FETCH_STATE.FAILED;
                state.transactionHistory.error = action.error.message;
            })
            .addCase(postAiiaRefreshPayment.pending, (state) => {
                state.aiiaRefreshPayment.status = FETCH_STATE.LOADING;
                state.aiiaRefreshPayment.error = null;
            })
            .addCase(postAiiaRefreshPayment.fulfilled, (state) => {
                state.aiiaRefreshPayment.status = FETCH_STATE.SUCCEEDED;
                state.aiiaRefreshPayment.error = null;
            })
            .addCase(postAiiaRefreshPayment.rejected, (state, action) => {
                state.aiiaRefreshPayment.status = FETCH_STATE.FAILED;
                state.aiiaRefreshPayment.error = action.payload;
            })
            .addCase(getAiiaRetryAuthorizeUrl.pending, (state) => {
                state.aiiaRetryAuthorize.status = FETCH_STATE.LOADING;
                state.aiiaRetryAuthorize.error = null;
            })
            .addCase(getAiiaRetryAuthorizeUrl.fulfilled, (state) => {
                state.aiiaRetryAuthorize.status = FETCH_STATE.SUCCEEDED;
                state.aiiaRetryAuthorize.error = null;
            })
            .addCase(getAiiaRetryAuthorizeUrl.rejected, (state, action) => {
                state.aiiaRetryAuthorize.status = FETCH_STATE.FAILED;
                state.aiiaRetryAuthorize.error = action.error.message;
            })
            .addCase(getAiiaRegenerateAuthorizeUrl.pending, (state) => {
                state.aiiaRegenerateAuthorizeUrl.status = FETCH_STATE.LOADING;
                state.aiiaRegenerateAuthorizeUrl.error = null;
            })
            .addCase(getAiiaRegenerateAuthorizeUrl.fulfilled, (state) => {
                state.aiiaRegenerateAuthorizeUrl.status = FETCH_STATE.SUCCEEDED;
                state.aiiaRegenerateAuthorizeUrl.error = null;
            })
            .addCase(
                getAiiaRegenerateAuthorizeUrl.rejected,
                (state, action) => {
                    state.aiiaRegenerateAuthorizeUrl.status =
                        FETCH_STATE.FAILED;
                    state.aiiaRegenerateAuthorizeUrl.error =
                        action.error.message;
                }
            )
            .addCase(getZenergyUsersDetailed.pending, (state) => {
                state.zenergyUsersDetailed.status = FETCH_STATE.LOADING;
                state.zenergyUsersDetailed.error = null;
            })
            .addCase(getZenergyUsersDetailed.fulfilled, (state, action) => {
                state.zenergyUsersDetailed.status = FETCH_STATE.SUCCEEDED;
                state.zenergyUsersDetailed.error = null;
                state.zenergyUsersDetailed.usersResponse = action.payload;
            })
            .addCase(getZenergyUsersDetailed.rejected, (state, action) => {
                state.zenergyUsersDetailed.status = FETCH_STATE.FAILED;
                state.zenergyUsersDetailed.error = action.error.message;
            })
            .addCase(getIntectUsersDetailed.pending, (state) => {
                state.intectUsersDetailed.status = FETCH_STATE.LOADING;
                state.intectUsersDetailed.error = null;
            })
            .addCase(getIntectUsersDetailed.fulfilled, (state, action) => {
                state.intectUsersDetailed.status = FETCH_STATE.SUCCEEDED;
                state.intectUsersDetailed.error = null;
                state.intectUsersDetailed.usersResponse = action.payload;
            })
            .addCase(getIntectUsersDetailed.rejected, (state, action) => {
                state.intectUsersDetailed.status = FETCH_STATE.FAILED;
                state.intectUsersDetailed.error = action.error.message;
            })
            .addCase(getDownloadTransactions.pending, (state) => {
                state.downloadTransactions.isLoading = true;
            })
            .addCase(getDownloadTransactions.fulfilled, (state, action) => {
                state.downloadTransactions.transactions = action.payload;
                state.downloadTransactions.isLoading = false;
            })
            .addCase(getDownloadTransactions.rejected, (state, action) => {
                state.downloadTransactions.error = action.error;
                state.downloadTransactions.isLoading = false;
            })
            .addCase(getCurrentCompany.pending, (state) => {
                state.currentCompany.isLoading = true;
            })
            .addCase(getCurrentCompany.fulfilled, (state, action) => {
                state.currentCompany.company = action.payload;
                state.currentCompany.isLoading = false;
            })
            .addCase(getCurrentCompany.rejected, (state, action) => {
                state.currentCompany.error = action.error;
                state.currentCompany.isLoading = false;
            })
            .addCase(getCurrentPaymentCompany.pending, (state) => {
                state.paymentCompany.status = FETCH_STATE.LOADING;
                state.paymentCompany.error = null;
            })
            .addCase(getCurrentPaymentCompany.fulfilled, (state, action) => {
                state.paymentCompany.status = FETCH_STATE.SUCCEEDED;
                state.paymentCompany.error = null;
                state.paymentCompany.company = action.payload;
            })
            .addCase(getCurrentPaymentCompany.rejected, (state, action) => {
                state.paymentCompany.status = FETCH_STATE.FAILED;
                state.paymentCompany.error = action.error.message;
            })
            .addCase(getZenegyDepartments.pending, (state) => {
                state.zenegyDepartments.status = FETCH_STATE.LOADING;
                state.zenegyDepartments.error = null;
            })
            .addCase(getZenegyDepartments.fulfilled, (state, action) => {
                state.zenegyDepartments.status = FETCH_STATE.SUCCEEDED;
                state.zenegyDepartments.error = null;
                state.zenegyDepartments.zenegyDepartments = action.payload;
            })
            .addCase(getZenegyDepartments.rejected, (state, action) => {
                state.zenegyDepartments.status = FETCH_STATE.FAILED;
                state.zenegyDepartments.error = action.error.message;
            })
            .addCase(getPlanDayEmployeeTypes.pending, (state) => {
                state.planDayEmployeeType.status = FETCH_STATE.LOADING;
                state.planDayEmployeeType.error = null;
            })
            .addCase(getPlanDayEmployeeTypes.fulfilled, (state, action) => {
                state.planDayEmployeeType.status = FETCH_STATE.SUCCEEDED;
                state.planDayEmployeeType.error = null;
                state.planDayEmployeeType.planDayEmployeeTypeResponse =
                    action.payload;
            })
            .addCase(getPlanDayEmployeeTypes.rejected, (state, action) => {
                state.planDayEmployeeType.status = FETCH_STATE.FAILED;
                state.planDayEmployeeType.error = action.error.message;
            })
            .addCase(getIntectDepartments.pending, (state) => {
                state.intectDepartments.status = FETCH_STATE.LOADING;
                state.intectDepartments.error = null;
            })
            .addCase(getIntectDepartments.fulfilled, (state, action) => {
                state.intectDepartments.status = FETCH_STATE.SUCCEEDED;
                state.intectDepartments.error = null;
                state.intectDepartments.intectDepartments = action.payload;
            })
            .addCase(getIntectDepartments.rejected, (state, action) => {
                state.intectDepartments.status = FETCH_STATE.FAILED;
                state.intectDepartments.error = action.error.message;
            })
            .addCase(getIntectEmploymentTemplates.pending, (state) => {
                state.intectEmployeeTemplates.status = FETCH_STATE.LOADING;
                state.intectEmployeeTemplates.error = null;
            })
            .addCase(
                getIntectEmploymentTemplates.fulfilled,
                (state, action) => {
                    state.intectEmployeeTemplates.status =
                        FETCH_STATE.SUCCEEDED;
                    state.intectEmployeeTemplates.error = null;
                    state.intectEmployeeTemplates.intectEmployeeTemplates =
                        action.payload;
                }
            )
            .addCase(getIntectEmploymentTemplates.rejected, (state, action) => {
                state.intectEmployeeTemplates.status = FETCH_STATE.FAILED;
                state.intectEmployeeTemplates.error = action.error.message;
            })
            .addCase(getReconciliationReport.pending, (state) => {
                state.reconciliationReportState.status = FETCH_STATE.LOADING;
                state.reconciliationReportState.error = null;
            })
            .addCase(getReconciliationReport.fulfilled, (state, action) => {
                state.reconciliationReportState.status = FETCH_STATE.SUCCEEDED;
                state.reconciliationReportState.error = null;
                state.reconciliationReportState.reconciliationReport =
                    action.payload;
            })
            .addCase(getReconciliationReport.rejected, (state, action) => {
                state.reconciliationReportState.status = FETCH_STATE.FAILED;
                state.reconciliationReportState.error = action.error.message;
            })
            .addCase(getCurrentCompanyBalanceData.pending, (state) => {
                state.currentCompanyBalance.isLoading = true;
            })
            .addCase(
                getCurrentCompanyBalanceData.fulfilled,
                (state, action) => {
                    state.currentCompanyBalance.list = action.payload;
                    state.currentCompanyBalance.isLoading = false;
                }
            )
            .addCase(getCurrentCompanyBalanceData.rejected, (state, action) => {
                state.currentCompanyBalance.error = action.error;
                state.currentCompanyBalance.isLoading = false;
            })
            .addCase(getUserTransactionsHistory.pending, (state) => {
                state.userTransactionHistory.status = FETCH_STATE.LOADING;
                state.userTransactionHistory.error = null;
            })
            .addCase(getUserTransactionsHistory.fulfilled, (state, action) => {
                state.userTransactionHistory.status = FETCH_STATE.SUCCEEDED;
                state.userTransactionHistory.transactions = action.payload;
                state.userTransactionHistory.error = null;
            })
            .addCase(getUserTransactionsHistory.rejected, (state, action) => {
                state.userTransactionHistory.status = FETCH_STATE.FAILED;
                state.userTransactionHistory.error = action.error.message;
                state.userTransactionHistory.transactions = undefined;
            })
            .addCase(getTimezonesList.pending, (state) => {
                state.timeZoneState.status = FETCH_STATE.LOADING;
                state.timeZoneState.error = null;
            })
            .addCase(getTimezonesList.fulfilled, (state, action) => {
                state.timeZoneState.status = FETCH_STATE.SUCCEEDED;
                state.timeZoneState.error = null;
                state.timeZoneState.list = action.payload;
            })
            .addCase(getTimezonesList.rejected, (state, action) => {
                state.timeZoneState.status = FETCH_STATE.FAILED;
                state.timeZoneState.error = action.error.message;
            })
            .addCase(getCompanyDetailsList.pending, (state) => {
                state.companyDetails.status = FETCH_STATE.LOADING;
                state.companyDetails.error = null;
            })
            .addCase(getCompanyDetailsList.fulfilled, (state, action) => {
                state.companyDetails.status = FETCH_STATE.SUCCEEDED;
                state.companyDetails.error = null;
                state.companyDetails.company = action.payload;
            })
            .addCase(getCompanyDetailsList.rejected, (state, action) => {
                state.companyDetails.status = FETCH_STATE.FAILED;
                state.companyDetails.error = action.error.message;
            })
            .addCase(updateCompanyInfo.pending, (state) => {
                state.updateCompanyDetails.status = FETCH_STATE.LOADING;
                state.updateCompanyDetails.error = null;
            })
            .addCase(updateCompanyInfo.fulfilled, (state) => {
                state.updateCompanyDetails.status = FETCH_STATE.SUCCEEDED;
                state.updateCompanyDetails.error = null;
            })
            .addCase(updateCompanyInfo.rejected, (state, action) => {
                state.updateCompanyDetails.status = FETCH_STATE.FAILED;
                state.updateCompanyDetails.error = action.error.message;
            })
            .addCase(getCompanyDanskeBankSettingsData.pending, (state) => {
                state.companyDanskeBankSettings.status = FETCH_STATE.LOADING;
            })
            .addCase(
                getCompanyDanskeBankSettingsData.fulfilled,
                (state, action) => {
                    state.companyDanskeBankSettings.status =
                        FETCH_STATE.SUCCEEDED;
                    state.companyDanskeBankSettings.list = action.payload;
                }
            )
            .addCase(
                getCompanyDanskeBankSettingsData.rejected,
                (state, action) => {
                    state.companyDanskeBankSettings.status = FETCH_STATE.FAILED;
                    state.companyDanskeBankSettings.error = action.error;
                }
            )
            .addCase(updateCompanyDanskeBankSettingsInfo.pending, (state) => {
                state.updateCompanyDanskeBank.status = FETCH_STATE.LOADING;
            })
            .addCase(updateCompanyDanskeBankSettingsInfo.fulfilled, (state) => {
                state.updateCompanyDanskeBank.status = FETCH_STATE.SUCCEEDED;
            })
            .addCase(
                updateCompanyDanskeBankSettingsInfo.rejected,
                (state, action) => {
                    state.updateCompanyDanskeBank.status = FETCH_STATE.FAILED;
                    state.updateCompanyDanskeBank.error = action.error.message;
                }
            )
            .addCase(getProviderIntegrationStatus.pending, (state) => {
                state.integrationstatus.status = FETCH_STATE.LOADING;
            })
            .addCase(
                getProviderIntegrationStatus.fulfilled,
                (state, action) => {
                    state.integrationstatus.status = FETCH_STATE.SUCCEEDED;
                    state.integrationstatus.list = action.payload;
                }
            )
            .addCase(getProviderIntegrationStatus.rejected, (state, action) => {
                state.integrationstatus.status = FETCH_STATE.FAILED;
                state.integrationstatus.error = action.error;
            })
            .addCase(getZenegyConnect.pending, (state) => {
                state.zenegyConnectUrl.status = FETCH_STATE.LOADING;
            })
            .addCase(getZenegyConnect.fulfilled, (state, action) => {
                state.zenegyConnectUrl.status = FETCH_STATE.SUCCEEDED;
                state.zenegyConnectUrl = action.payload;
            })
            .addCase(getZenegyConnect.rejected, (state, action) => {
                state.zenegyConnectUrl.status = FETCH_STATE.FAILED;
                state.zenegyConnectUrl.error = action.error;
            })
            .addCase(updateTimeRegistrationProviders.pending, (state) => {
                state.updateTimeRegistrationProviders.status =
                    FETCH_STATE.LOADING;
            })
            .addCase(
                updateTimeRegistrationProviders.fulfilled,
                (state, action) => {
                    state.updateTimeRegistrationProviders.status =
                        FETCH_STATE.SUCCEEDED;
                    state.updateTimeRegistrationProviders.list = action.payload;
                }
            )
            .addCase(
                updateTimeRegistrationProviders.rejected,
                (state, action) => {
                    state.updateTimeRegistrationProviders.status =
                        FETCH_STATE.FAILED;
                    state.updateTimeRegistrationProviders.error = action.error;
                }
            )
            .addCase(getPlandaySetup.pending, (state) => {
                state.plandaySetup.status = FETCH_STATE.LOADING;
            })
            .addCase(getPlandaySetup.fulfilled, (state, action) => {
                state.plandaySetup.status = FETCH_STATE.SUCCEEDED;
                state.plandaySetup.list = action.payload;
            })
            .addCase(getPlandaySetup.rejected, (state, action) => {
                state.plandaySetup.status = FETCH_STATE.FAILED;
                state.plandaySetup.error = action.error;
            })
            .addCase(intectAuthorizeSetup.pending, (state) => {
                state.intectAuthorizeState.status = FETCH_STATE.LOADING;
            })
            .addCase(intectAuthorizeSetup.fulfilled, (state, action) => {
                state.intectAuthorizeState.status = FETCH_STATE.SUCCEEDED;
                state.intectAuthorizeState.list = action.payload;
            })
            .addCase(intectAuthorizeSetup.rejected, (state, action) => {
                state.intectAuthorizeState.status = FETCH_STATE.FAILED;
                state.intectAuthorizeState.error = action.error;
            })
            .addCase(selectSalaryProvider.pending, (state) => {
                state.salaryProviderState.status = FETCH_STATE.LOADING;
            })
            .addCase(selectSalaryProvider.fulfilled, (state, action) => {
                state.salaryProviderState.status = FETCH_STATE.SUCCEEDED;
                state.salaryProviderState.salaryProvider = action.payload;
            })
            .addCase(selectSalaryProvider.rejected, (state, action) => {
                state.salaryProviderState.status = FETCH_STATE.FAILED;
                state.salaryProviderState.error = action.error;
            })
            .addCase(getZenegySalaryTypesList.pending, (state) => {
                state.salaryTypeState.status = FETCH_STATE.LOADING;
            })
            .addCase(getZenegySalaryTypesList.fulfilled, (state, action) => {
                state.salaryTypeState.status = FETCH_STATE.SUCCEEDED;
                state.salaryTypeState.salaryType = action.payload;
            })
            .addCase(getZenegySalaryTypesList.rejected, (state, action) => {
                state.salaryTypeState.status = FETCH_STATE.FAILED;
                state.salaryTypeState.error = action.error;
            })
            .addCase(getZenegySalaryModesList.pending, (state) => {
                state.salaryModeState.status = FETCH_STATE.LOADING;
            })
            .addCase(getZenegySalaryModesList.fulfilled, (state, action) => {
                state.salaryModeState.status = FETCH_STATE.SUCCEEDED;
                state.salaryModeState.salaryMode = action.payload;
            })
            .addCase(getZenegySalaryModesList.rejected, (state, action) => {
                state.salaryModeState.status = FETCH_STATE.FAILED;
                state.salaryModeState.error = action.error;
            })
            .addCase(updateZenegySalaryMode.pending, (state) => {
                state.updateSalaryMode.status = FETCH_STATE.LOADING;
            })
            .addCase(updateZenegySalaryMode.fulfilled, (state, action) => {
                state.updateSalaryMode.status = FETCH_STATE.SUCCEEDED;
                state.updateSalaryMode.data = action.payload;
            })
            .addCase(updateZenegySalaryMode.rejected, (state, action) => {
                state.updateSalaryMode.status = FETCH_STATE.FAILED;
                state.updateSalaryMode.error = action.error;
            })
            .addCase(updateZenegySalaryType.pending, (state) => {
                state.updateSalaryType.status = FETCH_STATE.LOADING;
            })
            .addCase(updateZenegySalaryType.fulfilled, (state, action) => {
                state.updateSalaryType.status = FETCH_STATE.SUCCEEDED;
                state.updateSalaryType.data = action.payload;
            })
            .addCase(updateZenegySalaryType.rejected, (state, action) => {
                state.updateSalaryType.status = FETCH_STATE.FAILED;
                state.updateSalaryType.error = action.error;
            })
            .addCase(updateZenegyEmployeeType.pending, (state) => {
                state.updateEmployeeType.status = FETCH_STATE.LOADING;
            })
            .addCase(updateZenegyEmployeeType.fulfilled, (state, action) => {
                state.updateEmployeeType.status = FETCH_STATE.SUCCEEDED;
                state.updateEmployeeType.employeeType = action.payload;
            })
            .addCase(updateZenegyEmployeeType.rejected, (state, action) => {
                state.updateEmployeeType.status = FETCH_STATE.FAILED;
                state.updateEmployeeType.error = action.error;
            })
            .addCase(getZenegyEmployeeTypesList.pending, (state) => {
                state.employeesType.status = FETCH_STATE.LOADING;
            })
            .addCase(getZenegyEmployeeTypesList.fulfilled, (state, action) => {
                state.employeesType.status = FETCH_STATE.SUCCEEDED;
                state.employeesType.list = action.payload;
            })
            .addCase(getZenegyEmployeeTypesList.rejected, (state, action) => {
                state.employeesType.status = FETCH_STATE.FAILED;
                state.employeesType.error = action.error;
            })
            .addCase(updateZenegyDepartmentsList.pending, (state) => {
                state.departmentState.status = FETCH_STATE.LOADING;
            })
            .addCase(updateZenegyDepartmentsList.fulfilled, (state, action) => {
                state.departmentState.status = FETCH_STATE.SUCCEEDED;
                state.departmentState.departments = action.payload;
            })
            .addCase(updateZenegyDepartmentsList.rejected, (state, action) => {
                state.departmentState.status = FETCH_STATE.FAILED;
                state.departmentState.error = action.error;
            })
            .addCase(currentImportJob.pending, (state) => {
                state.importJobState.status = FETCH_STATE.LOADING;
            })
            .addCase(currentImportJob.fulfilled, (state, action) => {
                state.importJobState.status = FETCH_STATE.SUCCEEDED;
                state.importJobState = action.payload;
            })
            .addCase(currentImportJob.rejected, (state, action) => {
                state.importJobState.status = FETCH_STATE.FAILED;
                state.importJobState.error = action.error;
            })
            .addCase(cancelCurrentJob.pending, (state) => {
                state.cancelJobState.status = FETCH_STATE.LOADING;
            })
            .addCase(cancelCurrentJob.fulfilled, (state, action) => {
                state.cancelJobState.status = FETCH_STATE.SUCCEEDED;
                state.cancelJobState = action.payload;
            })
            .addCase(cancelCurrentJob.rejected, (state, action) => {
                state.cancelJobState.status = FETCH_STATE.FAILED;
                state.cancelJobState.error = action.error;
            })
            .addCase(getCurrentJobStatus.pending, (state) => {
                state.currentJobStatusState.status = FETCH_STATE.LOADING;
            })
            .addCase(getCurrentJobStatus.fulfilled, (state, action) => {
                state.currentJobStatusState.status = FETCH_STATE.SUCCEEDED;
                state.currentJobStatusState = action.payload;
            })
            .addCase(getCurrentJobStatus.rejected, (state, action) => {
                state.currentJobStatusState.status = FETCH_STATE.FAILED;
                state.currentJobStatusState.error = action.error;
            })
            .addCase(importCurrentUsers.pending, (state) => {
                state.importUserState.status = FETCH_STATE.LOADING;
            })
            .addCase(importCurrentUsers.fulfilled, (state, action) => {
                state.importUserState.status = FETCH_STATE.SUCCEEDED;
                state.importUserState.users = action.payload;
            })
            .addCase(importCurrentUsers.rejected, (state, action) => {
                state.importUserState.status = FETCH_STATE.FAILED;
                state.importUserState.error = action.error;
            })
            .addCase(refreshCurrentImportParameters.pending, (state) => {
                state.refreshParamState.status = FETCH_STATE.LOADING;
            })
            .addCase(
                refreshCurrentImportParameters.fulfilled,
                (state, action) => {
                    state.refreshParamState.status = FETCH_STATE.SUCCEEDED;
                    state.refreshParamState = action.payload;
                }
            )
            .addCase(
                refreshCurrentImportParameters.rejected,
                (state, action) => {
                    state.refreshParamState.status = FETCH_STATE.FAILED;
                    state.refreshParamState.error = action.error;
                }
            )
            .addCase(getAdminCompanies.pending, (state) => {
                state.adminCompanies.status = FETCH_STATE.LOADING;
                state.adminCompanies.error = null;
            })
            .addCase(getAdminCompanies.fulfilled, (state, action) => {
                state.adminCompanies.status = FETCH_STATE.SUCCEEDED;
                state.adminCompanies.error = null;
                state.adminCompanies.companies = action.payload;
            })
            .addCase(getAdminCompanies.rejected, (state, action) => {
                state.adminCompanies.status = FETCH_STATE.FAILED;
                state.adminCompanies.error = action.error.message;
                state.adminCompanies.companies = [];
            })
            .addCase(aiiaConnectUrl.pending, (state) => {
                state.aiiaConnect.status = FETCH_STATE.LOADING;
            })
            .addCase(aiiaConnectUrl.fulfilled, (state, action) => {
                state.aiiaConnect.status = FETCH_STATE.SUCCEEDED;
                state.aiiaConnect.connectUrl = action.payload;
            })
            .addCase(aiiaConnectUrl.rejected, (state, action) => {
                state.aiiaConnect.status = FETCH_STATE.FAILED;
                state.aiiaConnect.error = action.error;
            })
            .addCase(updateIntectDepartmentsList.pending, (state) => {
                state.intectDepartmentState.status = FETCH_STATE.LOADING;
            })
            .addCase(updateIntectDepartmentsList.fulfilled, (state, action) => {
                state.intectDepartmentState.status = FETCH_STATE.SUCCEEDED;
                state.intectDepartmentState.departments = action.payload;
            })
            .addCase(updateIntectDepartmentsList.rejected, (state, action) => {
                state.intectDepartmentState.status = FETCH_STATE.FAILED;
                state.intectDepartmentState.error = action.error;
            })
            .addCase(updateIntectEmploymentTemplatesList.pending, (state) => {
                state.intectEmploymentTemplates.status = FETCH_STATE.LOADING;
            })
            .addCase(
                updateIntectEmploymentTemplatesList.fulfilled,
                (state, action) => {
                    state.intectDepartmentState.status = FETCH_STATE.SUCCEEDED;
                    state.intectEmploymentTemplates.employmentTemplates =
                        action.payload;
                }
            )
            .addCase(
                updateIntectEmploymentTemplatesList.rejected,
                (state, action) => {
                    state.intectEmploymentTemplates.status = FETCH_STATE.FAILED;
                    state.intectEmploymentTemplates.error = action.error;
                }
            )
            .addCase(failTransactionInSalary.pending, (state) => {
                state.failTransactions.status = FETCH_STATE.LOADING;
                state.failTransactions.error = null;
            })
            .addCase(failTransactionInSalary.fulfilled, (state, action) => {
                state.failTransactions.status = FETCH_STATE.SUCCEEDED;
                state.failTransactions.error = null;
                state.failTransactions.transactions = action.payload;
            })
            .addCase(failTransactionInSalary.rejected, (state, action) => {
                state.failTransactions.status = FETCH_STATE.FAILED;
                state.failTransactions.error = action.error.message;
                state.failTransactions.transactions = undefined;
            })
            .addCase(succeedTransactionInSalary.pending, (state) => {
                state.succeedTransactions.status = FETCH_STATE.LOADING;
                state.succeedTransactions.error = null;
            })
            .addCase(succeedTransactionInSalary.fulfilled, (state, action) => {
                state.succeedTransactions.status = FETCH_STATE.SUCCEEDED;
                state.succeedTransactions.error = null;
                state.succeedTransactions.transactions = action.payload;
            })
            .addCase(succeedTransactionInSalary.rejected, (state, action) => {
                state.succeedTransactions.status = FETCH_STATE.FAILED;
                state.succeedTransactions.error = action.error.message;
                state.succeedTransactions.transactions = undefined;
            })
            .addCase(approveTransactionInSalary.pending, (state) => {
                state.approveTransactions.status = FETCH_STATE.LOADING;
                state.approveTransactions.error = null;
            })
            .addCase(approveTransactionInSalary.fulfilled, (state, action) => {
                state.approveTransactions.status = FETCH_STATE.SUCCEEDED;
                state.approveTransactions.error = null;
                state.approveTransactions.transactions = action.payload;
            })
            .addCase(approveTransactionInSalary.rejected, (state, action) => {
                state.approveTransactions.status = FETCH_STATE.FAILED;
                state.approveTransactions.error = action.error.message;
                state.approveTransactions.transactions = undefined;
            })
            .addCase(rejectTransactionInSalary.pending, (state) => {
                state.rejectTransactions.status = FETCH_STATE.LOADING;
                state.rejectTransactions.error = null;
            })
            .addCase(rejectTransactionInSalary.fulfilled, (state, action) => {
                state.rejectTransactions.status = FETCH_STATE.SUCCEEDED;
                state.rejectTransactions.error = null;
                state.rejectTransactions.transactions = action.payload;
            })
            .addCase(rejectTransactionInSalary.rejected, (state, action) => {
                state.rejectTransactions.status = FETCH_STATE.FAILED;
                state.rejectTransactions.error = action.error.message;
                state.rejectTransactions.transactions = undefined;
            })
            .addCase(createCompanyAccountsTransaction.pending, (state) => {
                state.companyAccountTransaction.status = FETCH_STATE.LOADING;
                state.companyAccountTransaction.error = null;
            })
            .addCase(
                createCompanyAccountsTransaction.fulfilled,
                (state, action) => {
                    state.companyAccountTransaction.status =
                        FETCH_STATE.SUCCEEDED;
                    state.companyAccountTransaction.error = null;
                    state.companyAccountTransaction.data = action.payload;
                }
            )
            .addCase(
                createCompanyAccountsTransaction.rejected,
                (state, action) => {
                    state.companyAccountTransaction.status = FETCH_STATE.FAILED;
                    state.companyAccountTransaction.error =
                        action.error.message;
                    state.companyAccountTransaction.data = undefined;
                }
            )
            .addCase(getReconciliationPdf.pending, (state) => {
                state.exportReconciliationPdf.isLoading = true;
            })
            .addCase(getReconciliationPdf.fulfilled, (state, action) => {
                state.exportReconciliationPdf.data = action.payload;
                state.exportReconciliationPdf.isLoading = false;
            })
            .addCase(getReconciliationPdf.rejected, (state, action) => {
                state.exportReconciliationPdf.error = action.error;
                state.exportReconciliationPdf.isLoading = false;
            })
            .addCase(getTransactionsPdf.pending, (state) => {
                state.exportTransactionsPdf.isLoading = true;
            })
            .addCase(getTransactionsPdf.fulfilled, (state, action) => {
                state.exportTransactionsPdf.data = action.payload;
                state.exportTransactionsPdf.isLoading = false;
            })
            .addCase(getTransactionsPdf.rejected, (state, action) => {
                state.exportTransactionsPdf.error = action.error;
                state.exportTransactionsPdf.isLoading = false;
            });
    }
});

export default paymentsSlice.reducer;
