import { FormControlLabel, makeStyles } from '@mui/material';
import { Checkbox } from '../shared/CheckBox';

export type ConsentCheckboxProps = {
    label: React.ReactNode;
    checked: boolean;
    setChecked: (isChecked: boolean) => void;
};

export const ConsentCheckbox = (props: ConsentCheckboxProps) => {
    return (
        <FormControlLabel
            sx={{
                margin: '4px 0',
                alignItems: 'flex-start',

                '& .MuiFormControlLabel-label': {
                    maxWidth: '100%',
                    whiteSpace: 'normal'
                }
            }}
            label={props.label}
            control={
                <Checkbox
                    sx={{ marginTop: -1 }}
                    onChange={(e) => props.setChecked(e.target.checked)}
                    checked={props.checked}
                />
            }
        />
    );
};
