import { Dialog, FormGroup, styled } from '@mui/material';
import { useUser } from '../../contexts/user/UserContext';
import { useState } from 'react';
import { formatDate } from '../../utils/formatting';
import { TermsVersionsResponse } from '../../models/payment';
import { UpdateConsentsRequest } from '../../models/company';
import { ConsentFormActions } from './ConsentFormActions';
import { ConsentCheckbox } from './ConsentCheckbox';
import { Regular, SemiBold, SMRegular } from '../../typography/Typography';

export type FirstTimeConsentProps = {
    termsVersions: TermsVersionsResponse;
    updateConsents: (request: UpdateConsentsRequest) => void;
    postponeConsents: () => void;
};

const StyledDialog = styled(Dialog)`
    box-shadow: 0px 8px 8px -4px #10182808;
    box-shadow: 0px 20px 24px -4px #10182814;
    min-height: 100px;
    border-radius: 8px;
`;

export const FirstTimeConsent = (props: FirstTimeConsentProps) => {
    const { user, company } = useUser();
    const [commercialAndDataConsent, setCommercialAndDataConsent] =
        useState(false);
    const [newsletterConsent, setNewsletterConsent] = useState(false);
    const commercialTerms = props.termsVersions.termsVersions.find(
        (_) => _.consentType === 'Commercial'
    );
    const dataTerms = props.termsVersions.termsVersions.find(
        (_) => _.consentType === 'Data'
    );

    return (
        <StyledDialog
            open={true}
            fullWidth={true}
            maxWidth="sm"
            PaperProps={{
                style: {
                    borderRadius: '12px',
                    boxShadow:
                        '0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814',
                    padding: '28px 24px 16px 24px'
                }
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '16px'
                }}
            >
                <img
                    style={{
                        marginBottom: '16px'
                    }}
                    src="/consent_top_logo.svg"
                    alt="InstaPaid Logo"
                />
                <SemiBold>
                    {' '}
                    Hi {user.firstName}. Welcome to the InstaPaid BackOffice{' '}
                </SemiBold>
            </div>
            <div>
                <div
                    style={{
                        overflowY: 'auto',
                        maxHeight: '288px',
                        padding: '8px 8px 0px 8px',
                        border: '1px solid #D0D5DD'
                    }}
                >
                    <p>
                        <SMRegular>
                            We're thrilled to welcome you and {company.name}.
                            This is your company's dashboard, where you'll have
                            access to our services, tailored to fit your needs.
                        </SMRegular>
                    </p>
                    {company.endOfTrial !== null && (
                        <p>
                            <b>
                                <SMRegular>
                                    We're excited to present an exclusive
                                    introductory offer, allowing you to enjoy
                                    and assess our services entirely free of
                                    charge and without commitment until{' '}
                                    {formatDate(company.endOfTrial)}.
                                </SMRegular>
                            </b>
                        </p>
                    )}
                    <p>
                        <SMRegular>
                            If you have any questions or need assistance, our
                            dedicated support team is always ready to help.
                            Don't hesitate to reach out.
                        </SMRegular>
                    </p>

                    <p>
                        <SMRegular>
                            Thank you for choosing InstaPaid. Let's embark on
                            this exciting journey together!
                        </SMRegular>
                    </p>

                    <SMRegular> Warm Regards</SMRegular>

                    <p>
                        <Regular> Michael Kayser</Regular>
                        CEO
                    </p>
                </div>

                <FormGroup
                    style={{
                        margin: '16px 24px 8px 0px'
                    }}
                >
                    <ConsentCheckbox
                        checked={commercialAndDataConsent}
                        setChecked={(checked) =>
                            setCommercialAndDataConsent(checked)
                        }
                        label={
                            <SMRegular>
                                <span>I have read and accepted the </span>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={commercialTerms?.url}
                                >
                                    license agreement
                                </a>
                                <span> and the </span>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={dataTerms?.url}
                                >
                                    data processing agreement
                                </a>
                                .
                            </SMRegular>
                        }
                    />

                    <ConsentCheckbox
                        checked={newsletterConsent}
                        setChecked={(checked) => setNewsletterConsent(checked)}
                        label={
                            <SMRegular>
                                I hereby give my consent to receive newsletters
                                and information from InstaPaid via email or
                                through the InstaPaid application regarding
                                InstaPaid's product range, such as updates and
                                new features in the InstaPaid application.
                                InstaPaid will process my information, including
                                name, contact details, and company, for this
                                purpose.
                            </SMRegular>
                        }
                    />
                </FormGroup>
            </div>

            <ConsentFormActions
                commercialConsent={commercialAndDataConsent}
                dataConsent={commercialAndDataConsent}
                newsletterConsent={newsletterConsent}
                postponeConsents={props.postponeConsents}
                updateConsents={props.updateConsents}
                termsVersions={props.termsVersions}
            />
        </StyledDialog>
    );
};
